<template>
  <p class="open-sans-font custom-span-contact position-relative">
    <i class="fa fa-map position-absolute"></i>
    <span class="d-block">Address</span>Na Rybníčku 43 , Žulová 79065.
  </p>
  <!-- {/* End .custom-span-contact */} -->

  <p class="open-sans-font custom-span-contact position-relative">
    <i class="fa fa-envelope-open position-absolute"></i>
    <span class="d-block">Email</span>
    <a>j.svardala@seznam.cz</a>
  </p>
  <!-- {/* End .custom-span-contact */} -->

  <p class="open-sans-font custom-span-contact position-relative">
    <i class="fa fa-phone-square position-absolute"></i>
    <span class="d-block">Phone</span>
    <a>+420 721 892 661</a>
  </p>
  <!-- {/* End .custom-span-contact */} -->
</template>

<script>
export default {};
</script>
